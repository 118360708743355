import { navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import React, { Fragment, useEffect, useState } from 'react';

import s from './index.module.scss';
import cart_img from '../../../static/cart.png';

import { getUser } from '../../api/auth';
import { parseDate, getDate } from '../../utils/dates';
import { getReservations, deleteReservation } from '../../api/reservations';
import { useWindowDimensions } from '../../hooks/useDimension';

import Seo from '../../components/Seo';
import Button from '../../components/button';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Profile = () => {
  const { width } = useWindowDimensions();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  useEffect(() => {
    firebase.auth().onAuthStateChanged((res) => {
      if (!res) return navigate('/login');

      getUser(res.uid)
        .then((user) => {
          setUser(user);
          getReservations(user.id).then((res) => {
            setUser({ ...user, reservations: res });
            setLoading(false);
          });
        })
        .catch((err) => {
          console.log(err);
          navigate('/404');
        });
    });
  }, []);

  const onDeleteReservation = (id) => {
    setLoading(true);

    deleteReservation(id)
      .then(() => {
        getReservations(user.id).then((res) => {
          setUser({ ...user, reservations: res });
          setLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <meta
        name="viewport"
        content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1"
      />

      <Seo
        lang="es"
        description="Ver mi perfil, ver mis reservas y modificar contraseña"
        title="Mi perfil"
      />

      <Header title="Mi perfil" />

      <div className={s.container}>
        {!loading && (
          <div className={s.caja}>
            <div className={s.text}>
              <h2>Hola</h2>
              <h1>{user.fullName}</h1>
              <Button
                className="auth"
                value="Cambiar Contraseña"
                styles={{
                  background: '#ffc72c',
                }}
                onClick={() => navigate('/password-reset')}
              />
            </div>

            <div className={s.experiences}>
              <h2>Experiencias compradas</h2>

              {user.reservations && !user.reservations.length && (
                <div className={s.noreservas}>Aún no tenés reservas!</div>
              )}

              {user.reservations &&
                user.reservations.map((reservation) => (
                  <div className={s.border} key={reservation.id}>
                    <img src={cart_img} alt="img" className={s.cart_img} />

                    <div className={s.secondText}>
                      <p className={s.title}>Corona Sessions</p>
                      <p className={s.parrafo}>
                        {reservation.experienceId.title}
                      </p>
                      <p className={s.small}>
                        {reservation.experienceId.ubication}
                      </p>

                      <div className={s.peopleContainer}>
                        <small className={s.people}>
                          {reservation.people} personas
                        </small>

                        {parseDate(getDate()) > parseDate(reservation.day) && (
                          <Button
                            type="submit"
                            className="auth"
                            value="Cancelar reserva"
                            styles={{
                              background: '#ffc72c',
                              minWidth: '155px',
                              marginTop: '6px',
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              onDeleteReservation(reservation.id);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}

        {!loading && (
          <Footer styles={{ position: width <= 768 ? 'static' : 'absolute' }} />
        )}
      </div>
    </Fragment>
  );
};

export default Profile;
